<template>
  <output
    class="flex w-full items-center justify-between gap-4 p-4 text-white"
    :class="[notificationClasses.background, customBackgroundClass]"
    data-el="ui-notification-bar"
  >
    <UiIcon
      v-if="notificationIcon.systemNotification"
      v-bind="notificationIcon.systemNotification"
    />
    <div v-else />

    <HtmlParser
      v-if="props?.message"
      :html="props.message"
      class="text-xs font-bold sm:text-base"
    />

    <div>
      <UiButton
        v-if="props.closable"
        aria-label="close"
        class="group !block !text-white"
        variant="icon"
        @click.prevent="close"
      >
        <UiIcon
          aria-hidden="true"
          name="close"
          class="transition-colors group-hover:text-gray-300"
          :width="12"
          :height="12"
          data-el="ui-notification-bar__close-icon"
        />
      </UiButton>
    </div>
  </output>
</template>

<script lang="ts" setup>
import { onUnmounted, ref } from 'vue'
import UiButton from '../UiButton/UiButton.vue'
import UiIcon from '../UiIcon/UiIcon.vue'
import { useNotificationStyles } from '../../composables/useNotificationsUi'
import type {
  Notification,
  NotificationId,
} from '../../composables/useNotificationsUi'

const props = withDefaults(defineProps<Notification>(), {
  id: 0,
  type: 'info',
  closable: true,
  timeout: false,
  backgroundColor: null,
})

const emit = defineEmits<{
  (e: 'click:close', value: NotificationId): void
}>()

const { notificationIcon, notificationClasses } = useNotificationStyles(
  props.type,
)

const customBackgroundClass = computed<string | null>(() => {
  return props.backgroundColor ? `bg-notification-custom--${props.backgroundColor}` : null
})

const internalTimeout = ref<ReturnType<typeof setTimeout>>()

onMounted(() => {
  if (props.timeout) {
    internalTimeout.value = setTimeout(() => close(), props.timeout)
  }
})

function close() {
  emit('click:close', props.id)
}

onUnmounted(() => {
  if (props.timeout) {
    clearTimeout(internalTimeout.value)
  }
})
</script>

<style lang="postcss">
.bg-notification-custom--green {
  background-color: #429a5b;
}

.bg-notification-custom--orange {
  @apply bg-secondary;
}

.bg-notification-custom--black {
  @apply bg-black;
}

.bg-notification-custom--yellow {
  @apply bg-yellow text-primary;

  & [data-el='ui-notification-bar__close-icon'] {
    @apply text-primary;
  }
}
</style>
